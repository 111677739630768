//News Slider
const Flickity = require('flickity');

const flkty = new Flickity( '.b-news__slider', {
    // options
    cellAlign: 'left',
    contain: true
});

// Mobile Menu
const mobileMenu = ( ()=> {
    //Vars
    const   html = document.getElementsByTagName("html")[0],
            burgerIcon = document.querySelector('.masthead__burger'),
            menuChildren = [...document.querySelectorAll('.mobile-menu .menu-item-has-children')],
            menuDropDown = [...document.querySelectorAll('.masthead__nav .menu-item-has-children')];
    
    
    burgerIcon.addEventListener('click', () => {
        html.classList.toggle('nav-toggled');
        html.classList.toggle('no-scroll');
    });

    // Dropdown for Desktop Nav
    menuDropDown.forEach((el) => {
        el.addEventListener('mouseenter', () => {
            el.classList.add('is-active');
        });

        el.addEventListener('mouseleave', () => {
            el.classList.remove('is-active');
        });
    });

    // Dropdown for Mobile Nav
    menuChildren.forEach((el) => {
        el.addEventListener('click', () => {
            el.classList.toggle('is-active');
        });
    });

})();

//ScrollReveal
import ScrollReveal from 'scrollreveal';

var slideUp = {
    distance: '50px',
    origin: 'bottom',
    duration: 1500
};

ScrollReveal().reveal('.slideUp', slideUp);